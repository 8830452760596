const colors = {
    pink: {
        2: 'rgba(250, 0, 96, 0.02)',
        10: 'rgba(250, 0, 96, 0.1)',
        20: 'rgba(250, 0, 96, 0.2)',
        27: 'rgba(250,0,96,0.27)',
        30: 'rgba(250, 0, 96, 0.3)',
        50: 'rgba(250, 0, 96, 0.5)',
        67: 'rgba(250, 0, 96, 0.67)',
        100: '#FA0060',
    },
    orange: {
        100: '#FF844D',
    },
    blue: {
        100: '#51C5FA',
    },
    darkblue: {
        100: '#2E9DFD',
    },
    bullet_green: {
        100: '#18DB88',
    },
    facebook: {
        100: '#4267b2',
    },
    twitter: {
        100: '#1da1f2',
    },
    linkedin: {
        100: '#0077B5',
    },
    google: {
        100: '#db4437',
    },
    salt: {
        100: '#F4F4F4',
    },
    light_purple: {
        100: '#D6B4FF',
    },
    dark_purple: {
        100: '#37264A',
    },
    white: {
        3: 'rgba(255, 255, 255, 0.03)',
        6: 'rgba(255, 255, 255, 0.06)',
        8: 'rgba(255, 255, 255, 0.08)',
        10: 'rgba(255, 255, 255, 0.1)',
        20: 'rgba(255, 255, 255, 0.2)',
        30: 'rgba(255, 255, 255, 0.3)',
        40: 'rgba(255, 255, 255, 0.4)',
        50: 'rgba(255, 255, 255, 0.5)',
        80: 'rgba(255, 255, 255, 0.8)',
        100: '#FFFFFF',
        white_lighter: 'rgba(247, 247, 247,  0.39)',
        white_lightest: 'rgba(255, 255, 255, 0.01)',
    },
    black: {
        3: 'rgba(0, 0, 0, 0.03)',
        4: 'rgba(0, 0, 0, 0.04)',
        5: 'rgba(0, 0, 0, 0.05)',
        10: 'rgba(0, 0, 0, 0.1)',
        20: 'rgba(0, 0, 0, 0.2)',
        30: 'rgba(0, 0, 0, 0.3)',
        50: 'rgba(0, 0, 0, 0.5)',
        60: 'rgba(0, 0, 0, 0.6)',
        70: 'rgba(0, 0, 0, 0.7)',
        80: 'rgba(0, 0, 0, 0.8)',
        100: '#000000',
    },
    dark: {
        20: 'rgba(2,2,2,0.2)',
        50: 'rgba(2,2,2,0.5)',
        60: 'rgba(2,2,2,0.6)',
        65: 'rgba(2,2,2,0.65)',
        100: '#020202',
    },
    gray: {
        border: '#E0E0E0',
        border_light: 'rgba(224, 224, 224, 0.2)',
        lighter: '#CECECE',
        darker: '#242424',
        2: 'rgba(112,112,112,0.02)',
        20: 'rgba(112, 112, 112, 0.2)',
        30: 'rgba(112, 112, 112, 0.3)',
        50: 'rgba(112, 112, 112, 0.5)',
        70: 'rgba(112, 112, 112, 0.7)',
        100: '#707070',
        10: 'rgba(112, 112, 112, 0.10)',
    },
    gray_light: {
        10: 'rgba(240, 240, 240, 0.1)',
        20: 'rgba(240, 240, 240, 0.2)',
        40: 'rgba(240, 240, 240, 0.4)',
        50: 'rgba(240, 240, 240, 0.5)',
        100: '#F0F0F0',
    },
    gray_lighter: {
        lighter: '#f4f4f7',
        50: '#F4F3F8',
        100: '#FAFAFA',
    },
    gray_lightest: {
        50: '#F2F2F2',
        100: '#F5F5F5',
    },
    gray_dark: {
        6: 'rgba(29, 29, 31, 0.06)',
        10: 'rgba(29, 29, 31, 0.1)',
        20: 'rgba(29, 29, 31, 0.2)',
        25: 'rgba(29, 29, 31, 0.25)',
        40: 'rgba(29, 29, 31, 0.4)',
        50: 'rgba(29, 29, 31, 0.5)',
        60: 'rgba(29, 29, 31, 0.6)',
        90: 'rgba(29, 29, 31, 0.9)',
        100: '#1D1D1F',
    },
    gray_darker: {
        50: '#28282E',
        80: '#24242B',
        100: '#1E1F21',
    },
    dark_brown: {
        100: '#606062',
    },
    coal: {
        100: '#5B5B64',
    },
    velocity: {
        gradient: '#7F1CD3, #9A25C3, #F33A9A, #FF6E87, #FF9F86',
        pink: '#D834A7',
        purple: '#7F1CD3',
        dark: '#060E10',
    },
    health: {
        blue: '#3474FF',
        light_blue: '#558BFF',
        lightest_blue: '#F0F5FF',
        dark_blue: '#0A147D',
    },
    utilities: {
        gray_light: {
            100: '#EDEDED',
        },
        gray: {
            50: 'rgba(226, 226, 226, 0.5)',
            100: '#E2E2E2',
        },
    },
    event: {
        dark: '#000129',
    },
    financial: {
        main: '#16B4A9',
        main2: '#00f2e3',
        light: 'rgba(22, 180, 169, 0.19)',
        dark: 'rgba(22, 180, 169, 1)',
        100: 'rgba(22, 180, 169, 0.1)',
        200: 'rgba(22, 180, 169, 0.05)',
        500: 'rgba(22, 180, 169, 0.11)',
    },
    financialpage: {
        light: 'rgba(22, 180, 169, 0.19)',
        dark: 'rgba(10, 6, 72, 1)',
    },
    technologyDropdown: {
        dark: '#230B34',
        light: '#FCFCFF',
        box: '#F4F5F9',
        purpleBg: '#230D33',
        lightPurple: '#391651',
        16: 'rgba(244, 245, 249, 0.16)',
    },
    industriesDropdown: {
        bgColor: '#FCFCFC',
        menuDropdownGrey: '#B4B4B4',
    },
    caseStudyPages: {
        westernPara: '#808081',
        wuLandingHeading: '#341F40',
        bottomSolutionBg: '#290076',
    },
    digitalBg: '#F5F5F7',
    digitalWrapper: {
        bg1: 'rgb(30, 31, 33)',
        bg2: 'rgb(245, 245, 247)',
    },
    socialRep: {
        brandsBG: '#030323',
    },
    blogs: {
        detailHeaderBg: '#151515',
        h5Bg: '#14b4a8',
        h6Bg: '#6617ab',
        authorName: '#001b42',
    },
    ebook: {
        text: '#1D0438',
        seasonal: {
            main: '#9317FF',
            main2: '#FAD170',
            main3: 'rgba(147, 23, 255, 0.05)',
            pink: '#EBA0C0',
        },
        crypto: {
            main: '#050623',
            main2: '#4343EF',
        },
        whitepaper: {
            main: '#F5B4A0',
            main2: '#FFEEEA',
            main3: '#DFEBF6',
            lightBrown: '#FFF4F2',
            listBg: '#cadef1',
            darkBrown: '#C16D54',
            blurTrans: 'rgba(223, 235, 246, 0.39)',
            darkBlue: '#151A36',
        },
        digitalExp: {
            green: '#11E0D0',
        },
    },
    compMap: {
        headerBg: '#220E33',
    },
    resources: {
        cardPurple: '#6806A5',
    },
    fintech: {
        bgColor: '#FCFAFF',
        text: '#270D35',
        winnerBgColor: '#160321',
        80: 'rgb(39, 13, 53,  0.80)',
        50: 'rgb(39, 13, 53,  0.5)',
    },
    meet: {
        pink: '#FF0356',
        purple: '#351446',
        cta_bg: '#1B0726',
    },
    staffAug: {
        lightGrey: '#F7F2FA',
        border: '#e8e8e8',
        greyBorder: '#C3C3C3',
        green: '#37D36D',
    },
    reflecx: {
        main: '#FE4401',
        orange: '#FF7201',
        buildBg: '#EDF0F2',
    },
    redirectPg: {
        grey: '#606060',
        border: '#363636',
    },
    reflecx: {
        main: '#FE4401',
        orange: '#FF7201',
        buildBg: '#EDF0F2',
    },
    waveX: {
        greyBorder: '#DEDEEB',
        lightGreyBg: '#F5F5F8',
        darkText: '#321845',
        pageBgColor: '#F5F4F8',
        pageBgColor2: '#F0EFF5',
    },
    esg: {
        greyBorder: 'rgba(50,24,69,0.5)',
    },
};

export default colors;
